import Vue from 'vue'
import {EagleListConfigInterface, TableDataType} from '@/components/list/types/list'
import orderListConfig from 'modules/order/views/orderListConfig'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardOrderListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardOrderListConfig = orderListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    return {
      search: this.standardOrderListConfig.search,
      filter: this.standardOrderListConfig.filter,
      selectedData: this.standardOrderListConfig.selectedData,
      sort: this.standardOrderListConfig.sort,
      table: this.standardOrderListConfig.table,
    }
  }
}

export default new listConfig()
